import * as React from 'react';
import Layout from "../components/layout"

export default function Index() {
  return (
    <Layout>
      <h1>Random Keys</h1>
    </Layout>
  );
}
